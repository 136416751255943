<template>
  <v-container>
    <backArrow
      style="margin-left: 12px; margin-top: 5px; position: initial"
      :action="() => onClickBackArrow()"
    />
    <v-row class="px-3 py-3" style="height: 93vh;">
      <v-col cols="12" align-self="start">
        <v-row>
          <v-col cols="12" class="mb-0 pb-0">
            <p class="main-title mb-0 pb-0">Telemedicina</p>
            <p class="mt-2 font-weight-bold" style="color: var(--v-primary)">
              Status do pagamento
            </p>
          </v-col>

          <v-col col="12">
            <v-card loading loader-height="2" rounded="lg">
              <v-row class="pa-3">
                <v-col cols="12">
                  <v-icon class="animate-icon" large color="primary">fas fa-money-check-alt</v-icon>
                  <p class="text-center py-2 mb-0">{{ paymentStatus }}</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import backArrow from "../../components/covid/back-arrow";
import { mapMutations } from "vuex";
import SubscriptionService from "@/services/odoo/SubscriptionService";
import UserService from "@/services/security/User";

export default {
  name: 'PaymentCheckStatus',
  components: {
    backArrow,
  },
  data: () => ({
    subscriptionId: null,
    paymentStatus: 'Processando pagamento...',
    statusCheckSeconds: 15,
    intervalManager: null,
  }),
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    onClickBackArrow() {
      clearInterval(this.intervalManager);
      this.reloadSessionByToken()
      this.$router.push({ name: 'conectarComSuaEmpresa' });
    },
    verifyPaymentStatus() {
      if(this.subscriptionId) {

        this.subscriptionService.CheckPaymentStatus(this.subscriptionId)
          .then((response) => {
            if(response && response.data) {

              if(response.data.status === 'authorized' || response.data.status === 'captured') {
                // aprovado
                clearInterval(this.intervalManager);
                this.$router.push({ name: 'telemedicineFinishDependentAdhesion', params: { paymentStatus: 'ok' } })
              } else if (response.data.status === 'denied') {
                // não aprovado
                clearInterval(this.intervalManager);
                this.$router.push({ name: 'telemedicineFinishDependentAdhesion', params: { paymentStatus: 'error' } })
              }
            }
          })
          .catch(() => {
            this.paymentStatus = 'Ocorreu um problema ao tentar verificar o status do pagamento.'
          });
      }
    },
    async reloadSessionByToken() {
      this.loading(true)
      const token = this.$auth.getAccessToken();
      let reloadSessionResponse = await this.userService.reloadSessionByToken(token);
      try {
        if (reloadSessionResponse.data) {
          this.$auth.setUser(reloadSessionResponse.data.user);
        }
      } catch (e) {
        this.showmsg({text: "Erro ao recarregar dados da sessão.", type: "error"});
        this.loading(false);
      }
    }
  },
  destroyed() {
    clearInterval(this.intervalManager);
  },
  async mounted() {
    this.subscriptionService = new SubscriptionService();
    this.userService = new UserService();

    if(this.$route.params && this.$route.query.subscriptionId) {
      this.subscriptionId = this.$route.query.subscriptionId;
    }

    this.intervalManager = setInterval( () => {
       this.verifyPaymentStatus();
    }, (this.statusCheckSeconds * 1000));
  },
};
</script>

<style scoped>
.animate-icon {
  animation-name: leftToRight;
  animation-duration: 2.0s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


@keyframes leftToRight {
  0% {
    transform: translateX(-20vw);
  }
  100% {
    transform: translateX(100vw);
  }
}
</style>
